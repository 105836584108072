import React from "react"
import Img, { FixedObject } from "gatsby-image"

type Props = {
  logo: FixedObject
}

const Footer = ({ logo }: Props) => {
  return (
    <footer className={"bg-white"}>
      <div className="container mx-auto py-8 flex">
        <div className="max-w-md px-4">
          <Img fixed={logo} />
        </div>
      </div>
    </footer>
  )
}

export default Footer
