import React from "react"
import { Link } from "gatsby"
import Img, { FixedObject } from "gatsby-image"

import NavigationDesktop from "./Navigation-desktop"
import navigationItems from "./nav-items.json"
import { NavItems } from "./nav-items.interface"
import NavigationMobile from "./Navigation-mobile"

type Props = {
  logo: FixedObject
}

const Navigation = ({ logo }: Props) => {
  const [open, setOpen] = React.useState(false)
  const navItems = navigationItems as NavItems

  return (
    <nav className="bg-white p-3 relative shadow-md z-50">
      <div className="container mx-auto flex items-center flex-wrap">
        <Link to="/" className="flex">
          <Img fixed={logo} />
        </Link>
        <button
          className="text-gray-800 inline-flex p-3 hover:bg-gray-100 rounded lg:hidden ml-auto outline-none"
          onClick={() => setOpen(!open)}
        >
          <i className="far fa-bars" />
        </button>
        <div
          className={`w-full lg:inline-flex lg:flex-grow lg:w-auto ${
            !open ? "hidden" : ""
          }`}
          id="navigation"
        >
          <NavigationDesktop navItems={navItems} />
          <NavigationMobile navItems={navItems} />
        </div>
      </div>
    </nav>
  )
}

export default Navigation
