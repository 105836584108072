import React from "react"
import NavigationItem from "./Navigation-item"
import { Link } from "gatsby"
import { NavItems } from "./nav-items.interface"
import Dropdown from "../dropdown/Dropdown"
import DropdownItem from "../dropdown/Dropdown-item"

type Props = {
  navItems: NavItems
}

const NavigationDesktop = ({ navItems }: Props) => (
  <div className=" hidden lg:inline-flex lg:flex-row lg:ml-auto lg:w-auto w-full lg:items-center items-start flex flex-col lg:h-auto">
    {navItems.items.map(navItem => {
      if (navItem.button) {
        // Render a button if the button parameter is true
        return (
          <Link
            to={navItem.path}
            // @ts-ignore
            className="btn btn-primary lg:ml-8 mt-2 lg:mt-0"
            key={navItem.path}
          >
            {navItem.title}
          </Link>
        )
      } else if (navItem.children) {
        // Render a dropdown if an item hs children
        return (
          <Dropdown label={navItem.title} key={navItem.path} hover>
            {navItem.children.map(navItemChild => (
              <DropdownItem
                path={`${navItem.path}${navItemChild.path}`}
                key={navItemChild.path}
              >
                {navItemChild.title}
              </DropdownItem>
            ))}
          </Dropdown>
        )
      }

      // Render a default navigation item
      return (
        <NavigationItem to={navItem.path} key={navItem.path}>
          {navItem.title}
        </NavigationItem>
      )
    })}
  </div>
)

export default NavigationDesktop
