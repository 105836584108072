import React from "react"
import { NavItems } from "./nav-items.interface"
import { Link } from "gatsby"
import NavigationItem from "./Navigation-item"

type Props = {
  navItems: NavItems
}

const NavigationMobile = ({ navItems }: Props) => (
  <div className="inline-flex lg:hidden flex-col w-full">
    {navItems.items.map(navItem => {
      if (navItem.button) {
        // Render a button if the button parameter is true
        return (
          <Link
            to={navItem.path}
            // @ts-ignore
            className="btn btn-primary lg:ml-8 mt-2 lg:mt-0"
            key={navItem.path}
          >
            {navItem.title}
          </Link>
        )
      } else if (navItem.children) {
        return (
          <React.Fragment key={navItem.path}>
            <span
              key={navItem.path}
              className="w-full px-3 py-2 rounded text-gray-600 font-semibold items-center flex justify-between"
            >
              {navItem.title} <i className="fas fa-chevron-down fa-xs ml-2" />
            </span>
            {navItem.children.map(navItemChild => (
              <NavigationItem
                to={`${navItem.path}${navItemChild.path}`}
                key={navItemChild.path}
                fullWidth
                indent
              >
                {navItemChild.title}
              </NavigationItem>
            ))}
          </React.Fragment>
        )
      }

      return (
        <NavigationItem to={navItem.path} key={navItem.path}>
          {navItem.title}
        </NavigationItem>
      )
    })}
  </div>
)

export default NavigationMobile
