import React, { ReactNode } from "react"
// @ts-ignore
import { Transition } from "@tailwindui/react"

type Props = {
  label: string
  hover?: boolean
  children: ReactNode
}

const Dropdown = ({ label, hover = false, children }: Props) => {
  const [open, setOpen] = React.useState(false)

  return (
    <div
      className="relative inline-block text-left"
      onMouseOver={() => (hover ? setOpen(true) : null)}
      onMouseLeave={() => (hover ? setOpen(false) : null)}
    >
      <div>
        <button
          type="button"
          className="inline-flex lg:w-auto w-full px-3 py-2 rounded text-gray-600 items-center justify-between lg:justify-center hover:bg-gray-100 cursor-pointer"
          aria-haspopup="true"
          aria-expanded="true"
          onClick={() => (hover ? null : setOpen(!open))}
        >
          {label}
          <i className="fas fa-chevron-down fa-xs ml-2" />
        </button>
      </div>

      <Transition
        show={open}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        {(ref: any) => (
          <div
            ref={ref}
            className="origin-top-right absolute right-0 pt-2 w-56 rounded-md shadow-lg"
          >
            <div className="rounded-md bg-white shadow-xs">
              <div className="py-1" role="menu" aria-orientation="vertical">
                {children}
              </div>
            </div>
          </div>
        )}
      </Transition>
    </div>
  )
}

export default Dropdown
