import React, { ReactNode } from "react"
import { Link } from "gatsby"

type Props = {
  path: string
  children: ReactNode
}

const DropdownItem = ({ path, children }: Props) => {
  return (
    <Link
      to={path}
      // @ts-ignore
      className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
      role="menuitem"
    >
      {children}
    </Link>
  )
}

export default DropdownItem
