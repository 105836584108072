import React, { ReactNode } from "react"

import Navigation from "./Navigation/Navigation"
import SEO from "./SEO"
import { FixedObject } from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import Footer from "./Footer"

type Props = {
  title?: string
  description?: string
  children: ReactNode
}

const Layout = ({ children, title, description }: Props) => {
  const { file } = useStaticQuery<DataProps>(query)
  const logo = file.childImageSharp.fixed

  return (
    <div className="flex flex-col min-h-screen justify-between">
      <div>
        <SEO title={title} description={description} />
        <Navigation logo={logo} />
        {children}
      </div>
      <Footer logo={logo} />
    </div>
  )
}

export default Layout

type DataProps = {
  file: {
    childImageSharp: {
      fixed: FixedObject
    }
  }
}

const query = graphql`
  {
    file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fixed(height: 45) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
  }
`
