import React, { ReactNode } from "react"
import { Link } from "gatsby"

type Props = {
  to: string
  fullWidth?: boolean
  indent?: boolean
  children: ReactNode
}

const NavigationItem = ({ children, to, fullWidth, indent }: Props) => {
  return (
    <Link
      to={to}
      // @ts-ignore
      className={`lg:inline-flex ${
        fullWidth ? "" : "lg:w-auto justify-center"
      } ${
        indent ? "px-8" : "px-3"
      } w-full py-2 rounded text-gray-600 items-center hover:bg-gray-100`}
    >
      <span>{children}</span>
    </Link>
  )
}

export default NavigationItem
